/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-06",
    versionChannel: "nightly",
    buildDate: "2024-03-06T00:06:12.278Z",
    commitHash: "c260a456030454e23b0baa8013f1b8f77e715e82",
};
